import cli from './cli.png';
import logowhite from "./logowhite.png";
import basic from "./basic.png";
import vol from "./vol.png";
import deps from "./mole.png";
import timetravel from './timetravel.png'
import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga4'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import ContainerFluid from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./App.css";


function App() {
	ReactGA.event({
		category: 'Page',
		action: 'View'
	});
	return (
		<div className="App">
			<div className='topbar container-fluid'>
				<div style={{ float: 'left' }}><a href="https://www.disent.com"><img style={{ height: "125px", marginTop: '15px' }} src={logowhite} alt="Disent" /></a>
				</div>
			</div>
			<header className="App-header">
				<Container>
					<Row>
					<div className='headerlinkbar'>
				<a className="headerlink" href="https://dateroll.disent.com">DATEROLL</a> <span className='headerlinkdiv'>|</span>
				<a className="headerlink" href="https://repl.disent.com">REPL</a> <span className='headerlinkdiv'>|</span>
				<a className="headerlink" href="https://ui.disent.com">DESK🔒</a> <span className='headerlinkdiv'>|</span>
				<a className="headerlink" href="https://snpricer.disent.com">SNPRICER🔒</a>

					</div>
					</Row>
					<Row className="text-center  mt-3">
						<h1 style={{ 'fontWeight': 900, marginTop: 25, fontSize: '40pt' }}>
							Hassle-free Coding
						</h1>
						<br />
						<br />
					</Row>
					<Row className="text-left  mt-4">
						<Col>
							<p>We are a tech startup in NYC.
								We make coding more accessible to non-programmers for data analysis, idea generation, prototyping, workflows, and just general tinkering.
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="image-strip">

								<img alt="Code editor" className="image-strip-img" src={basic} />
								<img alt="Command Line Interface" className="image-strip-img" src={cli} />
								<img alt="Dependency Mapping" className="image-strip-img" src={deps} />
								<img alt="Debugging made easy" className="image-strip-img" src={timetravel} />
								<img alt="Data visualizer" className="image-strip-img" src={vol} />

							</div>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<p>
								Our users are analytical minds who enjoy mixing data and formulas together as they try out new ideas.
							</p>
							<p>We are here to make your workflows more productive. Spreadsheets are great until the formulas become unmanageable, when collaboration is required, and complex workbooks start to seize. Python alone is great until you need to manage environments, libraries, version control, building a front-end, or need to scale up to enterprise.</p>


						</Col>
					</Row>
					<Row className='mt-5'>
						<Col>
						<h1 className="mb-3">Products/Solutions</h1>
							<p>
								<b>Desk</b> is a web-based python environment for teams. <b>Desk</b> is built for busy teams who do not have the time 
								to deal with anything other then putting in code and getting answers. White glove support gives you immediate human access for questions on anything from using our product, python 101, hardcore programming, or design help.
							</p>
							<p>
								All of our products are based on Desk. <b>SNPricer</b> is a full fledged application for the structured notes community,&nbsp;<b>dateroll</b> is an open source python library that is tightly integrated with <b>Desk</b>, and <b>REPL</b> is a public python sandbox (ultra-light version of the <b>Desk</b> python engine) that lets anyone add a pre-programmed python prompt to their python docs with two lines of code.
							</p>
						</Col>
					</Row>
					<Row className="mt-4">

						<Col>	
							<h3 className="text-center"><b>Public / Open-Source Projects</b></h3>
							<br />
							<h4><a href="https://dateroll.disent.com">dateroll.disent.com</a></h4>

							<ul>
								<li>Open-source python library that makes <br/>working with dates drastically easier</li>
								<li>Available on pypi: <code>pip install dateroll</code></li>
								<li>Rapid financial product construction</li>
								<li>Lightweight holiday management layer</li>
								<li><em>O</em>(1) business day operations</li>
							</ul>
							<h4><a href="https://repl.disent.com">repl.disent.com</a></h4>

							<ul>
								<li>Public hosted python web service</li>
								<li>Embeddable python "prompt" widgets</li>
								<li>Make your docs come alive, or simply <br/>use to share your work with the world</li>
								<li>We use to make our <b>dateroll</b> <br/>documentation come alive</li>
							</ul>
						</Col>
						<Col>
							<h3 className="text-center"><b>Enterprise/Team Products</b></h3>
							<br />
							<h4><a href="https://desk.disent.com">desk.disent.com</a></h4>
							<ul>
								<li>Nothing to configure</li>
								<li>Super simple code editor for the rest of us</li>
								<li>Instant intelligent charts, tables, and forms</li>
								<li>All your components run freely (Automated DAG<br/> handles caching and multiproc)</li>
								<li>Call-a-human for python 101 or solution design</li>
								<li>Super intuitive team sharing capabilities found nowhere else</li>
								<li>Our recorder technology is going to replace debugging</li>
							</ul>

							<h4><a href="https://snpricer.disent.com">snpricer.disent.com</a></h4>
							<ul>
								<li>Turn-key desk analytics for structured notes and<br/> equity option strategies</li>
								<li>This is a webapp for traders, it does not require<br/> any python knowledge! (it's built on python)</li>
								<li>Extensive payoff support, data package options</li>
								<li>Full as-of reval with market data customization and re-pricing</li>
								<li>Full coverage for USD equity-linked market</li>
							</ul>


						</Col>

					</Row>
					<Row><Col>
						<p>Have any questions, or interested in a demo? <a href="mailto://team@disent.com?subject=[Disent] Demo request">email us!</a>.</p>
					</Col>
					</Row>
					<Row className="mt-4">

						<Col><h1>Our users</h1>
							<p>We focus predominately on high performance buy-side teams (not opposed to banks). We've worked with everyone from established trading teams to startup hedge funds. We're always looking into other areas such as investment banking and research.</p>

						</Col>

					</Row>
					<Row className="mt-4">
						<Col>
							<h3 className="mb-4">Interested in adopting python?
							&nbsp;&nbsp;&nbsp;<a style={{fontWeight:'900',textDecoration:'none',color:'black'}} href="mailto://team@disent.com?subject=[Disent] Lab Info Request">Apply for the Disent Lab.</a></h3>
							<p>
								For a small number of prospective clients we will design a solution for any problem you have, and if you like we can build it (for or with you), and work with you to maintain it, regardless of skill level.							
							</p>
							<p>	Our people are extremely talented with real world models that have deeply layered assumptions and small/sparse data availability.
								Past projects have been trading strategy migrations/implementations, and creation of centralized analytical libraries.
								Deep expertise in global capital markets and derivatives.
							</p>
						</Col>

					</Row>
					<Row className="mt-4">
						<Col>
							<h1>Team</h1>
							<br />
							<p>We are real people, <a href="mailto://team@disent.com">email us</a>.
							</p>
							<a href="https://www.linkedin.com/in/malizzio/">Anthony</a>
							<br />
							<a href="https://www.linkedin.com/in/niels-lauritzen-0b3271/">Niels</a>
							<br />
							<a href="https://www.linkedin.com/in/batudav/">Batu</a>
							<br />
							<a href="https://www.linkedin.com/in/christophermfiscella-8877b0161/">Chris</a>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<h3>Things we care about</h3>
							<ul>
								<li><a href="https://peps.python.org/pep-0020/#the-zen-of-python">Zen of python</a></li>
								<li>Spreadsheets are perhaps the best applications ever created.</li>
								<li>Negligible learning curves</li>
								<li>User experience (we make our own widgets, we re-write nearly every part of the tech stack)</li>
							</ul>
							<br /><br /><br /><br />
							<div style={{ textAlign: 'center', fontSize: '12pt', color: 'rgb(0,0,0,0.5)' }}>Copyright Disent 2024</div>
							<br />
						</Col></Row>
				</Container>
			</header>
		</div >
	);
}

export default App;
